/**
 * Returns the state of a form with an optional actionId
 */

import { useFormContext } from "./useFormContext.js";

export const useFormSubmitting = (actionId?: string) => {
  const formCtx = useFormContext();

  // Get the action ID of the submitting form
  const submittingFormActionid = formCtx?.fetcher.formData?.get("__actionId");

  // Action ID Matches or no action ID
  const actionIdMatch = actionId === submittingFormActionid || !actionId;

  const isSubmittingForm = formCtx?.mutating && actionIdMatch;

  return isSubmittingForm;
};
