import type { FetcherWithComponents } from "@remix-run/react";
import { createContext, useContext, useEffect } from "react";
import type { UseFormReturn } from "react-hook-form";

import type { ZodIssue } from "../models/ZodIssue.js";

/**
 * Props for the Form Element (anything UI specific)
 */

interface CompletionHandler {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
}

export interface ConfirmationDetails {
  title: string;
  description: string;
  confirmLabel: string;
  cancelLabel: string;
  /**
   * Text to copy for destruction action
   */
  destructiveText?: string;
}

export interface FormMutationOptions {
  handlers?: CompletionHandler;
  action?: string;
  actionHandlers?: Record<string, CompletionHandler>;
  confirmation?: ConfirmationDetails;
  actionConfirmation?: Record<string, ConfirmationDetails>;
  method?: "get" | "post";
  defaultValues?: any;
  values?: any;
  onChange?: (data: any) => void;
  shouldUnregister?: boolean;
  encType?: "application/x-www-form-urlencoded" | "multipart/form-data" | "text/plain" | undefined;
}

export interface FormContextType {
  formId?: string;
  fetcher: FetcherWithComponents<any>;
  options?: FormMutationOptions;
  mutating: boolean;
  formError?: string;
  formIssues?: Array<{
    message: string;
    code: string;
    path?: Array<string | number> | null;
  }>;
  setFormIssues: (issues: ZodIssue[]) => void;
  setFormError: (error: string) => void;
  methods: UseFormReturn<any>;
  clearIssues: () => void;
}

/**
 * Form Context that wraps forms to allow children to access form state
 */
export const FormContext = createContext<FormContextType | null>(null);

export const useFormContext = () => {
  return useContext(FormContext);
};

export const useFormFieldValue = (fieldPath: string) => {
  const d = useFormContext();

  return d?.methods.watch(fieldPath);
};

export const useFormFieldEffect = (effect: (value: any) => void) => {
  const d = useFormContext();

  useEffect(() => {
    if (d) {
      const subscription = d.methods.watch((value) => {
        effect(value);
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [d?.methods.watch, effect]);
};

export const useFormFieldSetValue = (fieldPath: string) => {
  const d = useFormContext();

  const setter = (value: any) => {
    d?.methods.setValue(fieldPath, value);
  };

  return setter;
};

export const useFormField = (fieldPath: string) => {
  const d = useFormContext();

  const setter = (value: any) => {
    d?.methods.setValue(fieldPath, value);
  };

  return [d?.methods.watch(fieldPath), setter] as const;
};

export const useFormFieldValues = () => {
  const d = useFormContext();
  return d?.methods.watch();
};
