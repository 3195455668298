import type { ComponentProps, ReactNode } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormProvider } from "react-hook-form";

import Button from "../../../components/Button/Button.js";
import Callout from "../../../components/Callout/Callout.js";
import { Dialog } from "../../../components/Dialog/Dialog.js";
import HStack from "../../../components/Stack/HStack.js";
import VStack from "../../../components/Stack/VStack.js";
import Paragraph from "../../../components/Typography/Paragraph.js";
import { useBool } from "../../../hooks/useBool.js";
import type { ConfirmationDetails } from "../../hooks/useFormContext.js";
import { FormContext } from "../../hooks/useFormContext.js";
import { type FormMutationState } from "../../hooks/useFormMutation.js";

export interface FormProps extends FormMutationState {
  className?: string;
  submitButton?: ComponentProps<typeof Button>;
  children?: ReactNode;
  toastOnlyErrors?: boolean;
  disabled?: boolean;
}

const MutationForm = ({
  formId,
  className,
  submitButton,
  children,
  options,
  fetcher,
  formError,
  formIssues,
  setFormIssues,
  setFormError,
  clearIssues,
  methods,
  toastOnlyErrors,
  disabled,
  mutating,
}: FormProps) => {
  const waitingForConfirmation = useBool();
  const [confirmationDetails, setConfirmationDetails] = useState<ConfirmationDetails>();
  const [confirmationActionId, setConfirmationActionId] = useState<string>();
  const formRef = useRef<HTMLFormElement>(null);

  // Change this to only hide the confirmation after submission is successfull. And show a loading icon
  const submitAfterConfirmation = useCallback(() => {
    /**
     * Submit the from in the same way a user would Make sure to pass in actionId as a field on the
     * form
     */
    if (confirmationActionId) {
      const inputField = document.createElement("input");

      // Set the attributes
      inputField.type = "hidden"; // Make sure it is not visible
      inputField.name = "__actionId"; // Replace this with the desired field name
      inputField.value = confirmationActionId; // Replace this with the desired field value

      formRef.current?.dispatchEvent(
        new Event("submit", {
          cancelable: true,
          bubbles: true,
        })
      );

      formRef.current?.removeChild(inputField);
    } else {
      formRef.current?.dispatchEvent(
        new Event("submit", {
          cancelable: true,
          bubbles: true,
        })
      );
    }

    // The dialog will automatically be hidden after the form submission in the useEffect below
  }, [fetcher, confirmationActionId]);

  // Effect for After Form Submission Successful
  useEffect(() => {
    if (fetcher.state === "idle") {
      waitingForConfirmation.setFalse();
    }
  }, [fetcher.state]);

  return (
    <div className={className}>
      {formError && !toastOnlyErrors && (
        <Callout title={formError} intent="error" className="mb-4" />
      )}
      <FormProvider {...methods}>
        <FormContext.Provider
          value={{
            formId,
            fetcher,
            mutating: fetcher.state !== "idle",
            options,
            formError,
            formIssues,
            setFormIssues,
            setFormError,
            methods,
            clearIssues,
          }}
        >
          <fetcher.Form
            id={formId}
            ref={formRef}
            encType={options?.encType}
            method={options?.method ?? "post"}
            action={options?.action}
            onSubmit={(e) => {
              /**
               * The form has a confirmation modal then stop submit Get value of __actionId input in
               * form Get Form Values
               */
              let showConfirmation = false;
              if (options?.confirmation) {
                showConfirmation = true;
                setConfirmationActionId(undefined);
                setConfirmationDetails(options.confirmation);
              }

              if (showConfirmation && !waitingForConfirmation.value) {
                waitingForConfirmation.setTrue();
                e.preventDefault();
              }
            }}
          >
            <fieldset disabled={disabled}>
              <VStack>
                <input type="hidden" name="__formId" value={formId} />
                {children}
                {submitButton && (
                  <HStack className="mt-4">
                    <Button
                      type="submit"
                      hotkey="command+enter"
                      hotkeyOptions={{
                        enableOnContentEditable: true,
                        enableOnFormTags: true,
                      }}
                      {...submitButton}
                    />
                  </HStack>
                )}
              </VStack>
            </fieldset>
          </fetcher.Form>
          {waitingForConfirmation.value && (
            <Dialog
              bool={waitingForConfirmation}
              title={confirmationDetails!.title}
              successCta={{
                label: confirmationDetails!.confirmLabel,
                loading: mutating,
              }}
              cancelCta={{
                label: confirmationDetails!.cancelLabel,
                disabled: mutating,
              }}
              onSuccess={() => { submitAfterConfirmation(); }}
              onCancel={() => { waitingForConfirmation.setFalse(); }}
              submitting={fetcher.state !== "idle"}
            >
              <Paragraph>{confirmationDetails!.description}</Paragraph>
            </Dialog>
          )}
        </FormContext.Provider>
      </FormProvider>
    </div>
  );
};

export default MutationForm;
